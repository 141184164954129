try {
    window.$ = window.jQuery = require('jquery');
    window.Popper = require('popper.js').default; // default is very important.
    require('bootstrap');

} catch (e) {}

import Swiper, {Navigation, Pagination, Lazy, Autoplay} from 'swiper';
Swiper.use([Pagination, Lazy, Navigation, Autoplay]);

import '@fancyapps/fancybox';
import 'jquery-smooth-scroll/jquery.smooth-scroll';
import 'jquery-match-height/dist/jquery.matchHeight';

//open-subnav
$('.opensubnav').click(function () {
    if ($(this).parent().next(".subnav").is(':visible')) {
        $(this).parent().next(".subnav").slideUp("200ms");
        $(this).html( '<i class="icon_plus_alt"></i>' );
    } else {
        $(this).parent().next(".subnav").slideDown("200ms");
        $(this).html( '<i class="icon_minus_alt"></i>' );
    }
});

$(document).ready(function(){

    $(window).scroll(function() {
        var offset = $(window).scrollTop();

        if(offset > 0) {
            $('body').addClass('scroll-offset');
        } else {
            $('body').removeClass('scroll-offset');
        }

    });

    //Hamburger
    $('.js-hamburger').click(function () {
        $(this).toggleClass('is-active');
        $('.main-menu').toggleClass('is-active');
    
        if ($('.main-menu').hasClass('is-active')) {
            $('body').addClass('nav-is-active');
        }else {
            $('body').removeClass('nav-is-active');
        }
    });
   

    $('.js-search').click(function () {
        $(".search-bar").slideToggle('active');
        return false;
    });

    

    if($(".masthead-swiper").length) {
        var surrCarousel = new Swiper('.masthead-swiper .swiper-container', {
            slidesPerView: '1',
            speed: 600,
            autoHeight: true,
            autoplay: {
                delay: 7000,
            },
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next',
            },
            loop: true, 
            clickable: false,

        });
    }

    // Scroll to id 
    // Select all »a« elements with a parent class »links« and add a function that is executed on click
    $( 'a' ).on( 'click', function(e){
        
        // Define variable of the clicked »a« element (»this«) and get its href value.
        var href = $(this).attr( 'href' );
        
        // Run a scroll animation to the position of the element which has the same id like the href value.
        $('html, body').animate({scrollTop: $(href).offset().top -120 }, '300');

        // Prevent the browser from showing the attribute name of the clicked link in the address bar
        e.preventDefault();
    
    });

});

